import * as frame from './frames/frame';
import * as modal from './frames/modal-frame';
import * as portal from './frames/portal-frame';
import * as signer from './frames/signer-frame';

export type ConstructorOptions = signer.SigningOptions &
  Omit<frame.BaseConfig, 'hostWindowId'>;

export default class ComponentsHost {
  private baseFrameConfig: frame.BaseConfig;
  private modal: modal.Frame;
  private signer: signer.Frame;

  private static WINDOW_ID = Math.random().toString(36).substring(2, 15);
  private static _instance: ComponentsHost;
  public static get instance() {
    return this._instance;
  }

  public static initialize(options: ConstructorOptions) {
    if (this._instance) {
      // TODO - add sentry?
      throw new Error(
        'Nmbr Components host already initialized. Access via `nmbr.instance`, or use the constructor directly.'
      );
    }

    this._instance = new ComponentsHost(options);

    return this._instance;
  }

  constructor({ sign, signingUrl, ...config }: ConstructorOptions) {
    delete config.apiHost;
    delete config.hostWindowId;

    this.baseFrameConfig = {
      ...config,
      hostWindowId: ComponentsHost.WINDOW_ID,
    };

    // start signer ASAP
    this.signer = new signer.Frame({
      ...this.baseFrameConfig,
      sign,
      signingUrl,
    });

    // modal will wait until the `window.load` event, or for an explicit call to
    // `show`, before adding itself to the page
    this.modal = new modal.Frame({ ...this.baseFrameConfig, name: 'modal' });
  }

  public load(
    name: string,
    container: HTMLElement,
    options?: {
      hideUntilReady?: boolean;
      params?: Record<string, string>;
    }
  ) {
    return new portal.Frame({
      name,
      container,
      modal: this.modal,
      ...this.baseFrameConfig,
      hideUntilReady: options?.hideUntilReady,
      params: options?.params,
    });
  }

  public static load(...args: Parameters<ComponentsHost['load']>) {
    if (!this._instance) {
      throw new Error('Nmbr Components host not initialized.');
    }

    return this._instance.load(...args);
  }

  public static async reinitialize(options: ConstructorOptions) {
    if (!this._instance) {
      throw new Error('Nmbr Components host not initialized.');
    }

    this._instance.reinitialize(options);
  }

  public async reinitialize(config: ConstructorOptions) {
    this.baseFrameConfig = Object.assign(this.baseFrameConfig, config);

    return frame.Frame.reconnectAll(this.baseFrameConfig);
  }
}
