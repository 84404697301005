import * as motion from 'motion';

const elementRectangles = new Map();

export const toggleFullscreen = (element?: HTMLElement) => {
  if (!element) {
    return;
  }

  if (element.style.position !== 'fixed') {
    enterFullscreen(element);
  } else {
    exitFullscreen(element);
  }
};

const enterFullscreen = (element: HTMLElement) => {
  const docRect = document.body.getBoundingClientRect();
  const rectangles = {
    self: element.getBoundingClientRect(),
    parent: element.parentElement?.getBoundingClientRect(),
    _style: {
      position: element.style.position,
      top: element.style.top,
      bottom: element.style.bottom,
      left: element.style.left,
      right: element.style.right,
      width: element.style.width,
      height: element.style.height,
    },
    _attributes: {
      width: element.getAttribute('width'),
      height: element.getAttribute('height'),
    },
  };

  elementRectangles.set(element, rectangles);

  element.style.position = 'fixed';
  element.style.top = `${rectangles.self.top}px`;
  element.style.bottom = `${rectangles.self.bottom}px`;
  element.style.left = `${rectangles.self.left}px`;
  element.style.right = `${rectangles.self.right}px`;
  element.style.width = `${rectangles.self.width}px`;
  element.style.height = `${rectangles.self.height}px`;

  element.setAttribute('width', `${rectangles.self.width}px`);
  element.setAttribute('height', `${rectangles.self.height}px`);

  motion.animate(element, {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: `${docRect.width}px`,
    height: `${docRect.height}px`,
  });
};

const exitFullscreen = (element: HTMLElement) => {
  const rectangles = elementRectangles.get(element);

  elementRectangles.delete(element);

  // TODO - animate the element back to its rightful position
  //        then, do the following in the next animation frame
  motion
    .animate(element, {
      top: `${rectangles.self.top}px`,
      bottom: `${rectangles.self.bottom}px`,
      left: `${rectangles.self.left}px`,
      right: `${rectangles.self.right}px`,
      width: `${rectangles.self.width}px`,
      height: `${rectangles.self.height}px`,
    })
    .finished.then(() => {
      element.style.position = rectangles._style.position;
      element.style.top = rectangles._style.top;
      element.style.bottom = rectangles._style.bottom;
      element.style.left = rectangles._style.left;
      element.style.right = rectangles._style.right;
      element.style.width = rectangles._style.width;
      element.style.height = rectangles._style.height;

      element.setAttribute('width', rectangles._attributes.width);
      element.setAttribute('height', rectangles._attributes.height);
    });
};
