import * as fullscreen from '../utils/fullscreen';
import * as frame from './frame';
import * as modal from './modal-frame';

export class Frame extends frame.Frame {
  protected REMOTE_API = {
    toggleFullscreen: () => {
      fullscreen.toggleFullscreen(this.iframe);
    },
    openModal: (options: modal.NavigateConfig) => {
      this.modal.open(options);
    },
  };

  private modal: modal.Frame;
  private _ready: Promise<void>;

  public get ready() {
    return this._ready;
  }

  constructor({
    container,
    modal,
    hideUntilReady = false,
    ...config
  }: frame.ConstructorArgs & {
    container: HTMLElement;
    modal: modal.Frame;
    hideUntilReady?: boolean;
  }) {
    super(config);

    this._ready = new Promise((resolve) => {
      this.iframe.addEventListener('load', () => resolve(), { once: true });
    });

    this.modal = modal;
    container.append(this.iframe);

    if (hideUntilReady) {
      this.iframe.style.display = 'none';
      this.ready.then(() => {
        this.iframe.style.display = 'block';
      });
    }

    modal.on('didClose', ({ didMutateData }) => {
      if (didMutateData) {
        this.connection?.remote.invalidateRouter();
      }
      this.connection?.remote.closeSnackbar();
    });
  }
}
