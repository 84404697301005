import { EventEmitter } from 'eventemitter3';

import * as fullscreen from '../utils/fullscreen';
import * as frame from './frame';

export type NavigateConfig = {
  to: string;
  search?: Record<string, string>;
  params?: true | Record<string, string | string[]>;
  // TODO - consider strongly typing this to the routes set in frames??
};

export class Frame extends frame.Frame {
  private emitter = new EventEmitter<{
    didClose: [{ didMutateData: boolean }];
  }>();

  protected REMOTE_API = {
    toggleFullscreen: () => {
      fullscreen.toggleFullscreen(this.iframe);
    },
    closeModal: () => this.hide(),
  };

  private overlay: HTMLElement;
  private _didAddOverlayToPage = false;
  private _didIFrameLoad = false;
  private _lastFocusedElement: HTMLElement | null = null;

  public get isShowing() {
    return this.overlay.style.display !== 'none';
  }

  constructor({ ...config }: Omit<frame.ConstructorArgs, 'name'>) {
    super({ name: 'modal', ...config });

    this.overlay = Frame.wrapFrame(this);

    // cache once our iframe has loaded, so `show` can be confident that a
    // loaded frame exists before assuming a connection does
    this.iframe.addEventListener(
      'load',
      () => {
        this._didIFrameLoad = true;
      },
      { once: true }
    );

    // wait until the document is complete (aka window has loaded)
    // before adding the overlay to the page
    if (document.readyState === 'complete') {
      this.addOverlayToPage();
    } else {
      window.addEventListener('load', () => this.addOverlayToPage(), {
        once: true,
      });
    }
  }

  // TODO - animate this
  public async open(config: NavigateConfig) {
    this.connection!.remote.navigate(config);
    await this.show();

    this._lastFocusedElement = document.activeElement;
    this.iframe.focus();
  }

  // TODO - animate these
  private async show() {
    if (!this._didIFrameLoad) {
      if (!this._didAddOverlayToPage) {
        // if we haven't yet added the overlay to the page, do so immediately
        this.addOverlayToPage();
      }

      // wait until the modal frame has loaded,
      // then wait one more tick to give it a chance to establish a `connection`
      await new Promise((resolve) => {
        this.iframe.addEventListener('load', () => setTimeout(resolve), {
          once: true,
        });
      });
    }

    if (!this.connection) {
      throw new Error('modal not connected');
    }

    this.overlay.style.display = 'block';
  }

  public async hide() {
    const didMutateData =
      (await this.connection?.remote.getDidMutateData()) ?? false;

    this.overlay.style.display = 'none';
    this.emitter.emit('didClose', { didMutateData });
    this.connection?.remote.navigate({ to: '/modal' });
    this.connection?.remote.invalidateRouter();
    this.connection?.remote.closeSnackbar();

    if (this._lastFocusedElement) {
      this._lastFocusedElement.focus();
      this._lastFocusedElement = null;
    }
  }

  private addOverlayToPage() {
    if (!this._didAddOverlayToPage) {
      this._didAddOverlayToPage = true;
      document.body.append(this.overlay);
    }
  }

  private static wrapFrame(frame: Frame) {
    const modalOverlay = document.createElement('div');
    const modalContainer = document.createElement('div');

    // style the overlay
    modalOverlay.style.position = 'fixed';
    modalOverlay.style.top = '0';
    modalOverlay.style.left = '0';
    modalOverlay.style.width = '100%';
    modalOverlay.style.height = '100%';
    modalOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
    modalOverlay.style.display = 'flex';
    modalOverlay.style.justifyContent = 'center';
    modalOverlay.style.alignItems = 'center';
    modalOverlay.style.zIndex = '2147483647';
    modalOverlay.style.backdropFilter = 'blur(10px)';
    modalOverlay.style.display = 'none';

    // style the container
    modalContainer.style.position = 'relative';
    modalContainer.style.width = '80%';
    modalContainer.style.height = '80%';
    modalContainer.style.margin = '5% auto';
    modalContainer.style.backgroundColor = 'white';
    modalContainer.style.borderRadius = '10px';
    modalContainer.style.overflow = 'hidden';

    // append the modal elements into the body
    modalContainer.append(frame.iframe);
    modalOverlay.append(modalContainer);

    modalOverlay.addEventListener('click', (event) => {
      if (event.target === modalOverlay) {
        frame.hide();
      }
    });

    document.addEventListener(
      'keydown',
      (event) => {
        if (event.key === 'Escape' && frame.isShowing) {
          frame.hide();
        }
      },
      { capture: true, passive: true }
    );

    return modalOverlay;
  }

  public on(...args: Parameters<typeof this.emitter.on>) {
    return this.emitter.on(...args);
  }

  public off(...args: Parameters<typeof this.emitter.off>) {
    return this.emitter.off(...args);
  }
}
