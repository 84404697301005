import * as frame from './frame';

export type SigningFunction = (message: string) => Promise<string>;
export type SigningOptions =
  | { signingUrl: string; sign?: never }
  | { signingUrl?: never; sign: SigningFunction };

export class Frame extends frame.Frame {
  protected REMOTE_API = {
    sign: (message: string) => this.sign(message),
    notifySignerUpdated: () => {},
    notifySignerErrored: (err: unknown) => {
      console.warn('Signer Error', err);
    },
  };

  private signingUrl?: string;
  private sign: SigningFunction = async (message: string) => {
    if (!this.signingUrl) {
      throw new Error('No signingUrl provided');
    }

    const response = await fetch(this.signingUrl, {
      method: 'POST',
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'application/json',
      },
      body: message,
    });

    return response.text();
  };

  constructor({
    signingUrl,
    sign,
    ...config
  }: SigningOptions & Omit<frame.ConstructorArgs, 'name'>) {
    super({ ...config, name: 'signer' });

    if (signingUrl) {
      this.signingUrl = signingUrl;
    }

    if (sign) {
      this.sign = sign;
    }

    this.iframe.style.display = 'none';
    document.body.prepend(this.iframe);
  }
}
